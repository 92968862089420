export const STATIC_TEXTS = {
  altLogo: "logo",
  menu: "menu ",
  footer: `atopowaskora.pl  ${new Date().getFullYear()} - Wszelkie prawa zastrzeżone.`,
  email: "jakubesuszynski@gmail.com",
  contact: "Kontakt",
  contactDescription: "Jeśli masz do nas jakieś pytanie, propozycję lub chcesz, aby Twoja historia z AZS trafiła na nasza stronę - skontaktuj się z nami!",
  cookiesUsage: "Korzystając ze strony, wyrażasz zgodę na wykorzystanie plików cookies w celu jej poprawnego działania, analizy ruchu i/lub wyświetlania spersonalizowanych reklam. Jeśli nie wyrażasz na to zgody - opuść tę stronę.",
  privacyPolicyPage: "Aby uzyskać więcej informacji zapoznaj się z naszą polityką prywatności: ",
  privacyPolicy: "Polityka Prywatności",
  name: "Imię",
  search: "Szukaj",
  searchResults: "Wyniki wyszukiwania",
  emailAddress: "email",
  message: "Wiadomość",
  submit: "Wyślij",
  wait: "Odczekaj 30 sekund",
  rodoAccept: "Wyrażam zgodę na przetwarzanie moich danych osobowych w celu odpowiedzi na pytanie lub opublikowania nadesłanej historii w dziale \"Wasze Historie\"",
  permission: "ZGODA_NA_PRZETWARZANIE",
  required: "To pole jest wymagane",
  errorMessage: "Wystąpił błąd. Spróbuj ponownie później",
  noResults: "Brak wyników",
  successMessage: "Dziękujemy! Odpowiemy najszybciej jak to możliwe.",
  similarPosts: "Podobne artykuły",
  tags: "Tagi",
  eventTitle: "Oswajamy Atopowe Zapalenie Skóry - Edycja 7",
  event: "Wydarzenie - ",
  eventSubTitle: "seria wykładów on-line",
  eventDate: "05.06.2024 - godz. 16:00",
  readMore: "Dowiedz się więcej",
  google: "https://www.google.com",
  writeToUs: "napisz do nas",
  yourStories: "wasze historie",
  attachement: "Dodaj załącznik (pdf, jpg, png do 5MB):",
  fileErrorMessage: "Plik przekracza dozwoloną wielkość"
}

export const CONSTS = {
  COOKIES_ACKNOWLEDGED: "cookiesAcknowledged",
  FORM: "Contact_Form"
}
